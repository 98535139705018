import { Container } from '@rugby-au/container';
import { ITheme, useTheme } from '@rugby-au/theme';
import React from 'react';
import { View } from 'react-native';
import { Text } from '@rugby-au/commons';

import { getBannerTextStyles } from './styles';
import { Image, RxImageProps } from '@rugby-au/image';

export const BannerImage = ({
  appearance = 'primary',
  text,
  alignment,
  image,
}: {
  appearance?: ITheme;
  text?: string;
  alignment?: 'left' | 'right' | 'center';
  image?: RxImageProps;
}) => {
  const { spacing, colorPairs, typography } = useTheme();
  const colors = colorPairs[appearance as keyof typeof colorPairs];

  const { ids, styles } = getBannerTextStyles({ spacing, colors, typography, alignment: alignment as string });
  return (
    <View>
      {image && (
        <View
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
          }}>
          <Image style={{ resizeMode: 'cover', aspectRatio: image.aspectRatio, flex: 1 }} url={image.url} alt={image.alt} />
        </View>
      )}
      {text && (
        <View style={{ position: 'relative' }}>
          <View style={{ ...styles.baseStyles, position: 'absolute', top: '50%', left: '50%' }}>
            <Container>
              <View dataSet={{ media: ids.textContainer }} style={styles.textContainer}>
                <Text dataSet={{ media: ids.textStyles }} style={styles.textStyles}>
                  {text}
                </Text>
              </View>
            </Container>
          </View>
        </View>
      )}
    </View>
  );
};
