import { Container } from '@rugby-au/container';
import { ITheme, useTheme } from '@rugby-au/theme';
import React from 'react';
import { View } from 'react-native';
import { Text } from '@rugby-au/commons';

import { getBannerTextStyles } from './styles';

export const BannerText = ({ appearance = 'primary', text, alignment }: { appearance?: ITheme; text?: string; alignment?: 'left' | 'right' | 'center' }) => {
  const { spacing, colorPairs, typography } = useTheme();
  const colors = colorPairs[appearance as keyof typeof colorPairs];

  const { ids, styles } = getBannerTextStyles({ spacing, colors, typography, alignment: alignment as string });
  return (
    <View style={styles.baseStyles}>
      <Container>
        <View dataSet={{ media: ids.textContainer }} style={styles.textContainer}>
          <Text dataSet={{ media: ids.textStyles }} style={styles.textStyles}>
            {text}
          </Text>
        </View>
      </Container>
    </View>
  );
};
