import React from 'react';
import { View } from 'react-native';

import { useAppConfig } from '@rugby-au/app-config';

import { MembershipCard, MembershipCardProps } from './MembershipCard';
import { useTheme } from '@rugby-au/theme';
import { getMembershipListForDesktop } from './styles';

export const MembershipList = ({ membershipList, numColumns }: { membershipList: MembershipCardProps[]; numColumns: number }) => {
  const { setNavigation } = useAppConfig();
  const { spacing } = useTheme();

  const { ids, styles } = getMembershipListForDesktop({ spacing, numColumns });
  return (
    <View style={styles.container} dataSet={{ media: ids.container }}>
      {membershipList.map(item => {
        return (
          <MembershipCard
            key={item.id}
            id={item.id}
            appearance={item.appearance}
            title={item.title}
            subTitle={item.subTitle}
            joinButton={{
              ...item.joinButton,
              onPress: () => {
                setNavigation && setNavigation(item.joinButton?.href ?? '');
              },
            }}
            summaryList={item.summaryList}
            moreInfoButton={{
              ...item.moreInfoButton,
              onPress: () => {
                setNavigation && setNavigation(item.moreInfoButton?.href ?? '');
              },
            }}
            image={item.image}
            onCardPress={() => {
              setNavigation && setNavigation(item.moreInfoButton?.href ?? '');
            }}
            numColumns={numColumns}
            isNative={false}
            isGradient={item.isGradient ?? false}
          />
        );
      })}
    </View>
  );
};
