import React, { useState } from 'react';
import { Pressable, View } from 'react-native';
// import Animated, { useSharedValue, useAnimatedStyle, Easing, withTiming, withSequence } from 'react-native-reanimated';
import LinearGradient from 'react-native-linear-gradient';

import { Image } from '@rugby-au/image';
import { ITheme, useTheme } from '@rugby-au/theme';
import { Button, ButtonProps } from '@rugby-au/button';
import { RxImageProps } from '@rugby-au/image';
import { Text } from '@rugby-au/commons';

import { getMembershipCardStyles } from './styles';
import { Icons, IconTypes } from '@rugby-au/icons';

export interface MembershipCardProps {
  id: string;
  appearance: ITheme;
  joinButton?: ButtonProps;
  moreInfoButton?: ButtonProps;
  subTitle?: string;
  summaryList: { icon: string; description: string }[];
  title?: string;
  image: RxImageProps;
  /** Whether the component is called from native file or desktop (browser) version */
  isNative?: boolean;
  isGradient?: boolean;
}
export const MembershipCard = ({
  // id,
  appearance = 'light',
  joinButton,
  moreInfoButton,
  subTitle,
  summaryList,
  title,
  image,
  onCardPress,
  numColumns,
  isNative,
  isGradient = false,
}: MembershipCardProps & { onCardPress: () => void; numColumns: number }) => {
  const [isHover, setIsHover] = useState<null | boolean>(null);

  const { colorPairs, spacing, radius, headings, typography } = useTheme();
  const colors = colorPairs[(appearance as keyof typeof colorPairs) ?? 'primary'];

  const styles = getMembershipCardStyles({ colors, spacing, radius, headings, typography, isHover, numColumns, isNative: isNative ?? true });

  // const offset = useSharedValue(10);
  // const opacity = useSharedValue(1);

  // const animatedStyles = useAnimatedStyle(() => {
  //   return {
  //     transform: [{ translateY: offset.value }],
  //     opacity: opacity.value,
  //     backgroundColor: colors.background.mid,
  //     borderBottomLeftRadius: radius.large,
  //     borderBottomRightRadius: radius.large,
  //     borderTopLeftRadius: radius.large,
  //     borderTopRightRadius: radius.large,
  //     flex: 1,
  //   };
  // });
  // useEffect(() => {
  //   opacity.value = 0;
  //   setTimeout(() => {
  //     offset.value = withSequence(
  //       withTiming(50, {
  //         duration: 100,
  //         easing: Easing.out(Easing.exp),
  //       }),
  //       withTiming(0, {
  //         duration: 1000,
  //         easing: Easing.out(Easing.exp),
  //       }),
  //     );
  //   }, Math.floor(Math.random() * 25));
  //   opacity.value = withTiming(1, { duration: 100, easing: Easing.out(Easing.exp) });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id]);

  const detailsContent = (
    <>
      {title && (
        <View>
          <Text style={styles.headerText}>{title}</Text>
        </View>
      )}
      {subTitle && (
        <View>
          <Text style={styles.subTitle}>{subTitle}</Text>
        </View>
      )}
      {summaryList && summaryList.length ? (
        <View style={styles.summaryListContainer}>
          {summaryList.map((item, index) => (
            <View key={index} style={styles.summaryItemContainer}>
              <View>
                <Icons type={item.icon as IconTypes} color={colors?.foreground.mid} width={typography.fontSize?.['3xlarge']} />
              </View>
              <Text style={{ color: colors?.foreground?.mid }}>{item.description}</Text>
            </View>
          ))}
        </View>
      ) : null}
      <View style={styles.buttonRow}>
        {joinButton && joinButton.title && (
          <View style={styles.buttonContainer}>
            <Button {...joinButton} borderRadius={radius.small} />
          </View>
        )}
        {moreInfoButton && moreInfoButton.title && (
          <View>
            <Button {...moreInfoButton} borderRadius={radius.small} />
          </View>
        )}
      </View>
    </>
  );
  return (
    <Pressable onHoverIn={() => setIsHover(true)} onHoverOut={() => setIsHover(false)} onPress={onCardPress} style={styles.container}>
      {/* <Animated.View style={[animatedStyles, { flex: styles.container.flex }]}> */}
      <View style={{ flex: styles.container.flex }}>
        <View style={styles.imageContainer}>
          <Image style={styles.imageStyles} url={image.url} alt={image.alt} />
        </View>
        {isGradient ? (
          <LinearGradient
            colors={
              isGradient ? [colors.background.light, colors.background.mid, colors.background.dark, colors.background.mid, colors.background.light] : [colors.background.mid]
            }
            useAngle={isGradient ? true : false}
            angle={45}
            angleCenter={{ x: 0.5, y: 0.5 }}
            style={styles.detailsContainer}>
            {detailsContent}
          </LinearGradient>
        ) : (
          <View style={styles.detailsContainer}>{detailsContent}</View>
        )}
        {/* </Animated.View> */}
      </View>
    </Pressable>
  );
};
