import { IColorGroup, IRadius, ISpacing, ITypography } from '@rugby-au/theme';
import { StyleSheet as RNStyleSheet } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const getMembershipCardStyles = ({
  spacing,
  radius,
  colors,
  headings,
  typography,
  isHover,
  numColumns,
  isNative,
}: {
  spacing: ISpacing;
  radius: IRadius;
  colors: IColorGroup;
  headings: any;
  typography: ITypography;
  isHover: boolean | null;
  numColumns: number;
  isNative: boolean;
}) => {
  return RNStyleSheet.create({
    container: {
      flex: 1 / numColumns,
      marginRight: isNative && numColumns > 1 ? spacing?.['3xlarge'] : 0,
      marginBottom: spacing?.['3xlarge'],
      alignContent: 'center',
      // transition: 'transform 0.1s ease-in-out',
      transform: [{ scale: isHover ? 1.01 : 1 }],
    },
    imageContainer: {},
    imageStyles: { aspectRatio: 3 / 1.75, resizeMode: 'cover', borderTopLeftRadius: radius.large, borderTopRightRadius: radius.large },
    detailsContainer: {
      flexGrow: 1,
      paddingVertical: spacing.large,
      paddingHorizontal: spacing?.['2xlarge'],
      borderBottomLeftRadius: radius.large,
      borderBottomRightRadius: radius.large,
      backgroundColor: colors.background.mid,
    },
    headerText: {
      ...headings[2],
      color: colors.foreground.mid,
      marginTop: spacing.large,
    },
    subTitle: {
      ...headings[3],
      fontFamily: typography.fontFamily.semiBold,
      marginBottom: spacing.large,
      color: colors.foreground.mid,
    },
    buttonRow: {
      // flex: 0,
      // flexWrap: 'wrap',
      flexDirection: 'row',
      marginBottom: spacing.medium,
      marginTop: 'auto',
    },
    buttonContainer: {
      marginRight: spacing.large,
    },
    summaryListContainer: { marginBottom: spacing.large },
    summaryItemContainer: { flexDirection: 'row', justifyContent: 'flex-start', marginBottom: spacing?.['2xsmall'], alignItems: 'center' },
  });
};

export const getMembershipListForDesktop = ({ spacing, numColumns }: { spacing: ISpacing; numColumns: number }) => {
  return StyleSheet.create({
    container: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridGap: spacing.large,
      '@media (min-width: 992px)': { gridTemplateColumns: `repeat(${numColumns}, 1fr)`, gridGap: spacing['5xlarge'] },
      flexGrow: 1,
    } as any,
  });
};
