import React from 'react';

import { ITheme } from '@rugby-au/theme';

import { BannerText } from './BannerText';
import { BannerImage } from './BannerImage';
import { RxImageProps } from '@rugby-au/image';

interface BannerProps {
  type: 'text' | 'image' | 'primary';
  appearance?: ITheme;
  text?: string;
  image?: RxImageProps;
}
export const Banner = ({ type, ...props }: BannerProps) => {
  if (type === 'text') {
    return <BannerText {...props} />;
  } else if (type === 'image') {
    return <BannerImage {...props} />;
  } else {
    // TODO add banner variants
    return <></>;
  }
};
