import StyleSheet from 'react-native-media-query';
import { IColorGroup, ISpacing, ITypography } from '@rugby-au/theme';

export const getBannerTextStyles = ({ colors, spacing, typography, alignment }: { colors: IColorGroup; spacing: ISpacing; typography: ITypography; alignment: string }) => {
  return StyleSheet.create({
    baseStyles: { backgroundColor: colors.background.mid },
    textContainer: {
      justifyContent: alignment === 'center' ? 'center' : alignment === 'left' ? 'flex-start' : 'flex-end',
      paddingVertical: spacing['4xlarge'],
      '@media (min-width: 768px)': { paddingVertical: spacing['5xlarge'] },
      '@media (min-width: 992px)': { paddingVertical: spacing['6xlarge'] },
    },
    textStyles: {
      color: colors.foreground.mid,
      fontSize: typography.fontSize['3xlarge'],
      '@media (min-width: 768px)': { fontSize: typography.fontSize['5xlarge'] },
      '@media (min-width: 992px)': { fontSize: typography.fontSize['6xlarge'] },
      fontFamily: typography.fontFamily.bold,
    },
  });
};
